import React from 'react';
import Slider from 'react-slick';

import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';

import SEO from '../components/commons/Seo';
import Logotypes from '../components/commons/Logotypes';

import ContentItem from '../components/ContentItem';
import Footer from '../components/commons/Footer';
import Section from '../components/commons/Section';
import RequestCatalogModal from '../components/commons/RequestCatalogModal';

import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { useIntl } from 'react-intl';
import useScrollTo from '../hooks/useScrollTo';

import ContentImg1 from '../images/kids/content1.png';
import ContentImg2 from '../images/kids/content2.png';
import ContentImg3 from '../images/kids/content3.png';

import '../styles/kids.scss';

const PLATFORMS = ['youtube', 'samsung', 'roku', 'dailymotion'];

const KidsContent = () => {
  const pageData = useStaticQuery(graphql`
    query {
      allPlatformsJson {
        nodes {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      allKidsCatalogJson {
        nodes {
          en {
            title
            image {
              childImageSharp {
                gatsbyImageData(formats: [JPG], placeholder: BLURRED)
              }
            }
          }
          ru {
            title
            image {
              childImageSharp {
                gatsbyImageData(formats: [JPG], placeholder: BLURRED)
              }
            }
          }
        }
      }
      # allFile(filter: { name: { eq: "Kedoo_Kids_Web_Catalogue_2020" }, ext: { eq: ".pdf" } }) {
      #   edges {
      #     node {
      #       publicURL
      #     }
      #   }
      # }
    }
  `);

  const { locale, formatMessage } = useIntl();
  const [footerRef, scrollToFooter] = useScrollTo(null);
  const [isShowRequestCatalogModal, setIsShowRequestCatalogModal] = React.useState(false);

  const platformLogotypes = PLATFORMS.map((id) =>
    pageData.allPlatformsJson.nodes.find((p) => p.id === id)
  );
  // const catalogFileUrl = pageData.allFile.edges[0].node.publicURL;

  const catalogItems = pageData.allKidsCatalogJson.nodes[0][locale];

  const onShowRequestCatagModal = () => {
    setIsShowRequestCatalogModal(true);
  };
  const onHideRequestCatagModal = () => {
    setIsShowRequestCatalogModal(false);
  };

  return (
    <>
      <SEO title={formatMessage({ id: 'kids.title' })} className="kedoo-kids" />
      <Section fullsize>
        <div className="decarations__cloud-top d-none d-md-block" />
        <div className="decarations__cloud-left d-none d-md-block" />
        <div className="decarations__cloud-middle d-none d-md-block" />
        <div className="decarations__cloud-right d-none d-md-block" />
        <div className="decarations__fish d-none d-md-block" />
        <div className="decarations__boat d-none d-md-block" />
        <div className="decarations__balloon d-md-none" />
        <div className="decarations__boat-sm d-md-none" />
        <div className="blue-waves"></div>
        <div className="fullsize-section__content-wrapper">
          <Section className="w-100">
            <Container>
              <Row>
                <Col md={8} xl={7}>
                  <div className="fullsize-section__content">
                    <div className="fullsize-section__logo kids-logo mb-2"></div>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({ id: 'kids.main_section.title' })
                      }}
                    />
                    <p
                      className="lead"
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({ id: 'kids.main_section.content' })
                      }}
                    />
                    <Button
                      variant="kids"
                      size="lg"
                      onClick={scrollToFooter}
                      className="d-flex align-items-center"
                    >
                      <span className="text-nowrap">
                        {formatMessage({ id: 'commons.contact_us' })}
                      </span>
                      <span className="right-arrow" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        </div>
      </Section>
      <main>
        <Jumbotron className="yellow-wave blue-bg">
          <div className="decorations__octopus"></div>
          <div className="decorations__bubbles"></div>
          <div className="decorations__coral"></div>
          <Section className="mb-n3">
            <Container>
              <h2
                className="pink-header"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'kids.approach_section.title' })
                }}
              />
              <ContentItem image={ContentImg1}>
                <h3>{formatMessage({ id: 'kids.approach_section.subtitle' })}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'kids.approach_section.content' })
                  }}
                />
              </ContentItem>
            </Container>
          </Section>
        </Jumbotron>

        <Jumbotron className="yellow-bg pt-0 pb-1">
          <Section>
            <Container>
              <ContentItem orderContentFirst image={ContentImg2} className="pink-dots mb-n2">
                <h2
                  className="blue-header"
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'kids.originals_section.title' })
                  }}
                />
                <h3>{formatMessage({ id: 'kids.originals_section.subtitle' })}</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'kids.originals_section.content' })
                  }}
                />
              </ContentItem>
            </Container>
          </Section>

          <Section>
            <Container>
              <h2
                className="pink-header"
                dangerouslySetInnerHTML={{
                  __html: formatMessage({ id: 'kids.toons_section.title' })
                }}
              />
              <ContentItem image={ContentImg3} className="mb-n1">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'kids.toons_section.subtitle' })
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatMessage({ id: 'kids.toons_section.content' })
                  }}
                />
              </ContentItem>
            </Container>
          </Section>

          <Section className="mb-2 mb-md-0">
            <Container>
              <Logotypes items={platformLogotypes} />
            </Container>
          </Section>

          <Section className="position-relative">
            <Container>
              <div className="decorations__coral3"></div>
              <ContentItem>
                <h2>{formatMessage({ id: 'kids.catalog_section.title' })}</h2>
                <p>{formatMessage({ id: 'kids.catalog_section.content' })}</p>
                {/* <Button variant="kids" size="lg" as="a" href={catalogFileUrl} target="_blank">
                  {formatMessage({ id: 'kids.catalog_section.see_catalog' })}
                </Button> */}
                <Button variant="kids" size="lg" onClick={onShowRequestCatagModal}>
                  {formatMessage({ id: 'kids.catalog_section.see_catalog' })}
                </Button>
              </ContentItem>
              <div className="catalog-items">
                <Slider
                  className="d-flex align-items-center py-2"
                  adaptiveHeight
                  speed={500}
                  variableWidth
                  swipeToSlide
                  infinite={false}
                >
                  {catalogItems.map(({ image, title }, i) => (
                    <div key={i} className="mb-2 mr-3">
                      <div className="catalog-item">
                        <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={title} />
                        <p>{title}</p>
                      </div>
                    </div>
                  ))}
                  <div className="mb-2 mr-3">
                    {/* <a
                      href={catalogFileUrl}
                      target="_blank"
                      className="catalog-item__new d-flex align-items-center justify-content-center"
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({ id: 'kids.catalog_section.see_catalog_item' })
                      }}
                    /> */}
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        onShowRequestCatagModal();
                      }}
                      className="catalog-item__new d-flex align-items-center justify-content-center"
                      dangerouslySetInnerHTML={{
                        __html: formatMessage({ id: 'kids.catalog_section.see_catalog_item' })
                      }}
                    />
                  </div>
                </Slider>
              </div>
            </Container>
          </Section>
        </Jumbotron>
      </main>
      <div className="footer-wrapper">
        <div className="decorations__coral2"></div>
        <Footer ref={footerRef} variant="kids" />
        <RequestCatalogModal
          show={isShowRequestCatalogModal}
          onHide={onHideRequestCatagModal}
          catalogName="kids"
        />
      </div>
    </>
  );
};

export default KidsContent;
